import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as MagnifyingGlassLoading } from 'images/magnifying-glass.svg';
import { ReactComponent as BankLoading } from 'images/bank-loader.svg';
import StateContainer from 'components/StateContainer';
import { useSelector } from 'react-redux';
import { getLoanOffer } from 'selectors/getLoanOfferData';
import { getApplicationData } from 'selectors/getApplicationData';
import { getApplicationData as getApplicationDataThunk } from 'thunks';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { RoutePath } from 'enums/Routes';
import { ErrorType } from 'components/Error/ErrorType';
import { LoanOfferStep } from 'api/LoanOfferApi';

import styles from './Loader.module.scss';

const Loader = () => {
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const navigate = useNavigate();

  const [progress, setProgress] = useState(0);
  const [accountSync, setAccountSync] = useState(false);
  const [totalAccounts, setTotalAccounts] = useState(0);
  const [syncedAccounts, setSyncedAccounts] = useState(0);

  const offer = useSelector(getLoanOffer);
  const { application, isLoading: isLoadingApplicaiton } = useSelector(getApplicationData);

  useEffect(() => {
    if (offer.isCompleted && offer.response.data.application_id && !isLoadingApplicaiton && application === undefined) {
      dispatchWithUnwrap(getApplicationDataThunk(offer.response.data.application_id!));
    }
    if (!offer.isLoading && (offer.isError || (offer.isCompleted && !offer.response.passed))) {
      navigate(RoutePath.Error, { state: { type: ErrorType.CantGenerateOffer } });
    }
    if (offer.isLoading && offer.progress) {
      switch (offer.progress.step) {
        case LoanOfferStep.ValidatedInput:
          setProgress(10);
          break;
        case LoanOfferStep.GettingTradelines:
          if (offer.progress.totalAccounts !== undefined && offer.progress.syncingAccounts !== undefined) {
            setAccountSync(true);
            setProgress(70 - (50 * offer.progress.syncingAccounts) / offer.progress.totalAccounts);
            setTotalAccounts(offer.progress.totalAccounts);
            setSyncedAccounts(offer.progress.totalAccounts - offer.progress.syncingAccounts);
          } else {
            setProgress(20);
          }
          break;
        case LoanOfferStep.RunningDecisionEngine:
          setProgress(75);
          break;
        case LoanOfferStep.GeneratingDebtProfile:
          setProgress(80);
          break;
        case LoanOfferStep.CreatingHardOffer:
          setProgress(90);
          break;
        default:
          break;
      }
    }
  }, [offer]);

  let title = "We're analyzing your finances...";
  if (accountSync) {
    title = syncedAccounts
      ? `Synced ${syncedAccounts}/${totalAccounts} accounts`
      : `We've found ${totalAccounts} accounts`;
  }

  return (
    <div className={styles.container}>
      <StateContainer
        icon={accountSync ? <BankLoading /> : <MagnifyingGlassLoading />}
        progress={progress}
        title={title}
      />
    </div>
  );
};

export default Loader;
